import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$(function(){
	

	 /////////////////////////// load
  

	$(document).ready(function() {
	  	init();
	  	toggleMenu();
	  	jumpHash();
	  	slider();
	  	hoverMenu();
	  	closeMenu();
	  	
	});
  
  /////////////////////////// resize
  $(window).on('resize', onResize)
  function onResize(){
    

    
  }

  /////////////////////////// scroll
  $(window).on('scroll', onScroll)
  function onScroll(){
		let pos = $(window).scrollTop();

		if (pos > 500) {
			$('.scrollTop').addClass('is-show');
		} else {
			$('.scrollTop').removeClass('is-show');
  		}
   
  }


	const jumpHash = () => {
		
	    $('a[href^="#"]').click(function(){


	    	var speed = 1000;
			var href= $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$("html, body").animate({scrollTop:position}, speed, "swing");
				return false;
		});

	}

	const init = () => {
		var winW = $(window).width(),
	    winH = $(window).height();
	  //ローディング画面

		// const result = new Promise((resolve) => {
		// 	$('#loading').delay(100).fadeOut(1200);
		// 	$(".wrapper").removeClass('hidden');
		// 	return resolve();
		//   });
	}

	function slider() {
		$('.mainVisual--top').slick({
	    	infinite: true, //スライドのループ有効化
		    speed: 1000, //切り替えのスピード（小さくすると速くなる）
		    arrows: false,
		    autoplay: true,
		    autoplaySpeed: 4000,
		    dots: true,
		    dotsClass: 'slick-dots'
	  });
	}

	function toggleMenu() {
		$('.hamburger').click(function(){
			console.log('asdfghjk');
			if($(this).hasClass('open')) {
				$(this).removeClass('open');
				$('.headerMenu').removeClass('is-open');
			} else {
				$(this).addClass('open');
				$('.headerMenu').addClass('is-open');
			}
		});
	}

	const hoverMenu = () => {
	
		$('.gnav__link').mouseover(function() {
			$('.gnav__linkEn',this).addClass('is-hidden');
			$('.gnav__linkJa',this).addClass('is-show');
		}).mouseout(function() {
 
		    $('.gnav__linkEn',this).removeClass('is-hidden');
			$('.gnav__linkJa',this).removeClass('is-show');
		 
		});
	}

	const closeMenu = () => {
		$('.headerMenu__link').on('click', ()=> {
			$('.hamburger').removeClass('open');
			$('.headerMenu').removeClass('is-open');
		})
		
	}

	


	


});

